

.menuLinkWrapper {
  overflow: hidden;
  font-family: 'Butler Ultra Light';
  font-size: 8vh;
  border-bottom: 1px solid #ffffff;
  display: flex;
}

.marqueeMenu{
  white-space: nowrap;
  text-transform: uppercase;
}

.marqueeMenu:hover{
  color: #ffffff;
  font-style: italic;
}

.smallerText{
  font-family: 'Butler Ultra Light';
  font-size: 2vh;
  padding-left: 1vw;
  padding-right: 1vw;
} 