@font-face {
     font-family: 'Cormorant';
     src: url('https://fonts.gstatic.com/s/cormorant/v13/DXIgQW4Lr6z7qP5xJ3m2i8K7s4.woff2') format('woff2'),
         url('https://fonts.gstatic.com/s/cormorant/v13/DXIgQW4Lr6z7qP5xJ3m2i8K7s4.woff') format('woff'),
         url('https://fonts.gstatic.com/s/cormorant/v13/DXIgQW4Lr6z7qP5xJ3m2i8K7s4.ttf') format('truetype');
     font-weight: normal;
     font-style: normal;
  }
  
@font-face {
     font-family: 'Cormorant';
     src: url('https://fonts.gstatic.com/s/cormorant/v13/DXIgQW4Lr6z7qP5xJ3m2i8K7s4-italic.woff2') format('woff2'),
         url('https://fonts.gstatic.com/s/cormorant/v13/DXIgQW4Lr6z7qP5xJ3m2i8K7s4-italic.woff') format('woff'),
         url('https://fonts.gstatic.com/s/cormorant/v13/DXIgQW4Lr6z7qP5xJ3m2i8K7s4-italic.ttf') format('truetype');
     font-weight: normal;
     font-style: italic;
  }
  
@font-face {
     font-family: 'Cormorant';
     src: url('https://fonts.gstatic.com/s/cormorant/v13/DXIgQW4Lr6z7qP5xJ3m2i8K7s4-bold.woff2') format('woff2'),
         url('https://fonts.gstatic.com/s/cormorant/v13/DXIgQW4Lr6z7qP5xJ3m2i8K7s4-bold.woff') format('woff'),
         url('https://fonts.gstatic.com/s/cormorant/v13/DXIgQW4Lr6z7qP5xJ3m2i8K7s4-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
@font-face {
     font-family: 'Cormorant';
     src: url('https://fonts.gstatic.com/s/cormorant/v13/DXIgQW4Lr6z7qP5xJ3m2i8K7s4-bolditalic.woff2') format('woff2'),
         url('https://fonts.gstatic.com/s/cormorant/v13/DXIgQW4Lr6z7qP5xJ3m2i8K7s4-bolditalic.woff') format('woff'),
         url('https://fonts.gstatic.com/s/cormorant/v13/DXIgQW4Lr6z7qP5xJ3m2i8K7s4-bolditalic.ttf') format('truetype');
     font-weight: bold;
     font-style: italic;
  }

      @font-face {
        font-family: 'Butler';
        src: url('./butler/Butler-Medium.woff2') format('woff2'),
            url('./butler/Butler-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Butler';
        src: url('./butler/Butler.woff2') format('woff2'),
            url('./butler/Butler.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Butler';
        src: url('./butler/Butler-ExtraBold.woff2') format('woff2'),
            url('./butler/Butler-ExtraBold.woff') format('woff');
        font-weight: 800;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Butler';
        src: url('./butler/Butler-Black.woff2') format('woff2'),
            url('./butler/Butler-Black.woff') format('woff');
        font-weight: 900;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Butler';
        src: url('./butler/Butler-UltraLight.woff2') format('woff2'),
            url('./butler/Butler-UltraLight.woff') format('woff');
        font-weight: 200;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Butler';
        src: url('./butler/Butler-Bold.woff2') format('woff2'),
            url('./butler/Butler-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Butler';
        src: url('./butler/Butler-Light.woff2') format('woff2'),
            url('./butler/Butler-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
    }
 
    
/*butler stencil*/
@font-face {
  font-family: 'Butler Stencil';
  src: url('./butler/ButlerStencil-Medium.woff2') format('woff2'),
      url('./butler/ButlerStencil-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('./butler/ButlerStencil.woff2') format('woff2'),
      url('./butler/ButlerStencil.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('./butler/ButlerStencil-ExtraBold.woff2') format('woff2'),
      url('./butler/ButlerStencil-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('./butler/ButlerStencil-Black.woff2') format('woff2'),
      url('./butler/ButlerStencil-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('./butler/ButlerStencil-UltraLight.woff2') format('woff2'),
      url('./butler/ButlerStencil-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('./butler/ButlerStencil-Bold.woff2') format('woff2'),
      url('./butler/ButlerStencil-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Butler Stencil';
  src: url('./butler/ButlerStencil-Light.woff2') format('woff2'),
      url('./butler/ButlerStencil-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* butler OTF */
@font-face {
  font-family: 'Butler Ultra Light';
  src: url('./butler/Butler_Ultra_Light.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}
