
.marqueeAsideContainer {
  position: relative;
  padding-left: 2vh;
    }

.marqueeAsideText {
        transform: rotate(270deg);
        transform-origin: left bottom;
        white-space: nowrap;
        display: inline-block;
        line-height: 1;
        position: absolute;
        animation: marquee linear infinite;
        animation-duration: 20s;
        font-size: 3vh;
        font-family: Cabin;
        font-style: italic;
        font-weight: lighter;
      }     
      @keyframes marquee {
        0% {
          top: 1vw;
        }
        100% {
          top: 200vw
        }
      }