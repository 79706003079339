

/* diamond hover over grid */

.gallery {
    --s: 20vh; /* control the size 150px*/
    display: grid;
    grid: auto-flow var(--s) / repeat(2,var(--s));
    gap: 0;
    place-items: center;
    padding: calc(var(--s)/2);
    transform: rotate(45deg);
    position: relative;
    z-index: 100;
  }
  .gallery > img {
    width: 141%;
    aspect-ratio: 1;
    object-fit: cover;
    filter: sepia(50%);
    transform: scale(var(--_t,1)) rotate(-45deg);
    clip-path: polygon(50% 0,100% 50%,50% 100%,0 50%);
    cursor: pointer;
    transition: .2s linear;
  
  }
  .gallery > img:hover {
    filter: sepia(0);
    --_t: 1.55;
  }
  
    
  .circle{
      width: calc(98% - 1px);
      height: calc(13vh - 1px);
      border: 1px solid white;
      border-radius: 50%;
      margin: -3vh auto auto auto;
      animation: move 10s infinite;
  }
  @keyframes move {
    0% {
      transform: translateY(-140vh); 
    }
    100% {
      transform: translateY(110vh);
     
    }
  
  } 