
.littleBitAtTheEndOfTheMenu{
    font-size: 2vh;
    font-weight: 100;
    font-family: 'Butler Ultra Light';
}
.littleBitAtTheEndOfTheMenu {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: transparent;
    -webkit-text-stroke: 1px #000000;
    position: relative;
    display: inline-block;
  }
  
  .littleBitAtTheEndOfTheMenu::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    color: #d4ff00;
    -webkit-text-stroke: 0px #d4ff00;
    overflow: hidden;
    transition: width 0.5s ease-in-out;
  }
  
  .littleBitAtTheEndOfTheMenu:hover::before {
    width: 100%;
  }