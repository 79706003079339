body {
  /*font-family: 'Helvetica', 'Arial', sans-serif; */
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
  color: #333;
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;

  background: rgb(47,176,13);
  background: -moz-linear-gradient(270deg, rgba(47,176,13,1) 8%, rgba(20,115,180,1) 92%);
  background: -webkit-linear-gradient(270deg, rgba(47,176,13,1) 8%, rgba(20,115,180,1) 92%);
  background: linear-gradient(270deg, rgba(47,176,13,1) 8%, rgba(20,115,180,1) 92%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2fb00d",endColorstr="#1473b4",GradientType=1);
}



