.homeContainer{
  display: flex;
  flex-direction: column;
  height: 100vh; 
}
 .intro{
  font-family: 'Butler Ultra Light';
  font-size: 4vh;
  flex: 1;
 }

 .IntroText {
  /* Other styling */
  transition: box-shadow 0.3s ease;
}

.IntroText.glow {

  animation: glowEffect 1s linear infinite;;
}

@keyframes glowEffect {
  0% {
    text-shadow: 0 0 10px #ffffff;
  }
  25% {
    text-shadow: 0 0 10px #ffee00;
  }
  50% {
    text-shadow: 0 0 10px #ffffff;
  }
  100% {
    text-shadow: 0 0 10px #ffee00;
  }
}
 
 /* bottom right rotating gear*/
  .guiltyGear {
    position: relative;
    flex: 1;
    top: 11vw;
    left: 12vw;
    scale: 1.7;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .guiltyGear:hover{
    transition: 1s;
    filter: drop-shadow(2px 14px 16px purple);
  }
  
  .spin {
    -webkit-animation:spin 7s linear infinite;
    -moz-animation:spin 7s linear infinite;
    animation:spin 7s linear infinite;
  }
  
  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

