.header-logo {
    @apply flex items-center justify-center;
  }

  .titleText {
    color: black;
    font-family: 'Butler Ultra Light';
    position: absolute;
    top: 10vw;
    left: -8vw;
    transform: rotate(270deg);
    font-size: 19vw;
    transition: 1s;
  }

  .titleText-L:hover{
    transition: 3s;
    color:rgb(7, 241, 202);
    transform: rotate(360deg)!;
  }